import './Header.css';

const Header = ({createList}) => {
  return (
    <div className="header">
      <div>BEST TODO APP EVER</div>
    </div>
  );
}


export default Header;